<template>
  <div class="End" v-touch:swipe.right="onSwipeRight">
      <img src="../assets/img/class/end.png"  ondragstart="return false;" alt="">
  </div>
</template>
<script>
// eslint-disable-next-line import/no-extraneous-dependencies
// import * as d3 from 'd3';
export default {
  data() {
    return {
      title: '',
      user: '',
      level: '',
      img: '',
    };
  },
  created() {
    this.user = JSON.parse(localStorage.getItem('student'));
  },
  mounted() {
    // js
  },
  methods: {
    onSwipeRight() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="less">
.End{
    width: 100vw;
    height: 100vh;
    background: #fffdfa;
    img{
        width: 100%;
    }
}
</style>
